<template>
  <div class="container-3ds">
    <!-- This is for local env testing -->
    <div v-if="displayDebugUrl">
      <template v-if="!debugUrlRedirect">
        <div class="body-text-x-medium mb-s8">
          To test 3DS locally, click the link below and wait for it to redirect back to dibbs. Once it does, paste the URL full path (without domain) at the input below.
        </div>
        <a :href="circle3dsUrl" class="underline" target="_blank" rel="noopener noreferrer">Open 3DS</a>
        <CustomInput
          class="mt-s8"
          v-model="debugUrlRedirect"
          testId="debugUrlRedirect"
          placeholder="Paste the 3ds redirect URL"
          type="text"
        />
      </template>
      <iframe
        v-else
        :src="debugUrlRedirect"
        height="100%"
        width="100%"
      />
    </div>

    <!-- This is the actual staging and production iframe -->
    <iframe
      v-else-if="circle3dsUrl"
      :src="circle3dsUrl"
      height="100%"
      width="100%"
    />
    <div v-else>
      <Loading version="v3"/>
    </div>
  </div>
</template>

<script>
import { CustomInput, Loading } from '@/components/misc';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Card3ds',
  components: {
    CustomInput,
    Loading,
  },

  data() {
    return {
      debugUrlRedirect: null,
    };
  },

  computed: {
    ...mapState('wallet', [
      'ongoingDepositData',
    ]),

    displayDebugUrl() {
      return process.env.NODE_ENV !== 'production';
    },

    circle3dsUrl() {
      return this.lodashGet(this.ongoingDepositData, 'initResult.redirect_url', null);
    }
  },

  methods: {
    ...mapActions('wallet', ['finishCardDeposit']),

    callbackFrom3dsIframe(e) {
      if (e.data && e.data.type === 'circle-deposit-card-callback' && e.data.id && e.origin === window.location.origin) {
        this.finishCardDeposit(e.data);
      }
    }
  },

  mounted () {
    window.addEventListener('message', this.callbackFrom3dsIframe);
  },

  beforeDestroy () {
    window.removeEventListener('message', this.callbackFrom3dsIframe);
  }
};
</script>

<style scoped>
  .container-3ds {
    @apply max-w-full max-h-full;
    width: 450px;
    height: 650px;
  }
</style>
